import React from 'react';
import { Link } from "react-router-dom";
import styled from 'styled-components';
import responsive from '../../../config/responsive';

const StyledRouterLink = styled.a`
    display: inline-block;
    font-size: ${responsive.font.default.size3};
    font-weight: 300;
    border: 1px solid #121212;
    padding: 10px 20px;
    color: #121212;
    text-decoration: none;

    &:hover {
        color: #FFF;
        background-color: #121212;
    }

    @media (max-width: ${responsive.size.desktop.small}) {
        display: table;
        margin: 0 auto;
    }

    @media (max-width: ${responsive.size.tablet.landscape}) {
        font-size: ${responsive.font.mobile.size3}
    }
`;

class Button extends React.Component {
    render() {
    return <StyledRouterLink href={this.props.href} target={this.props.target}>{this.props.text}</StyledRouterLink>
    }
}

export default Button;