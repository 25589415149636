import React from 'react';
import styled from 'styled-components';
import Title from '../../components/atoms/Title/Title';
import Text from '../../components/atoms/Text/Text';
import AwardsData from './data.js';
import responsive from '../../config/responsive';

const StyledTable = styled.div`
    display: table;
    margin: 0 auto;
`;

const StyledWrapper = styled.div`
    display: block;
    margin: 0 auto;
    padding: 30px 0;
`;

const StyledLogo = styled.img`
    display: block;
    width: 180px;
    margin: 0 auto;
    padding-bottom: 20px;

    @media (max-width: ${responsive.size.tablet.landscape}) {
        width: 120px;
    }
`;

const StyledPrimaryTitle = styled.div`
    font-weight: 500;
    font-size: ${responsive.font.default.size3};
    text-align: center;
    span {
        font-weight: 600;
    }

    @media (max-width: ${responsive.size.tablet.landscape}) {
        font-size: ${responsive.font.mobile.size3};
        padding: 5px 0;
    }
`;

const StyledSecondaryTitle = styled.div`
    color: #121212;
    text-decoration: none;
    font-weight: 300;
    font-size: ${responsive.font.default.size4};
    text-align: center;
    padding: 0px 0;

    @media (max-width: ${responsive.size.tablet.landscape}) {
        font-size: ${responsive.font.mobile.size4};
    }
`;

class Awards extends React.Component {
    
    render() {

        let renderedAwardsData = AwardsData.map((row,index) => {
            return(
                <StyledWrapper key={index}>
                    <StyledLogo src={row.award.logo.src} srcset={row.award.logo.srcSet} />
                    <StyledSecondaryTitle>{row.award.name}</StyledSecondaryTitle>
                    <StyledPrimaryTitle><span>{row.project.brand}</span> / {row.project.title}</StyledPrimaryTitle>
                    <StyledSecondaryTitle>{row.award.category}</StyledSecondaryTitle>
                </StyledWrapper>
            );
        });

        return (
        <div className="page-row">
            <div className="page-column left">
                    <Title primary="Awarded" secondary="Projects" />
                    <Text text="These are the projects that I have worked as a part of some teams and have been awarded." />
            </div>
            <div className="page-column right">
                <StyledTable>
                    {renderedAwardsData}
                </StyledTable>
            </div>
        </div>
        );
    }
}

export default Awards;