import React from 'react';
import styled from 'styled-components';
import Title from '../../components/atoms/Title/Title';
import Text from '../../components/atoms/Text/Text';
import List from '../../components/atoms/List/List';
import ProjectDetailData from './data.js';

const StyledYoutubeEmbedContainer = styled.div`
    position: relative;
    width: 90%;
    height: 0;
    padding-bottom: 56.25%;
    margin: 0 auto;
`;

const StyledYoutubeEmbed = styled.iframe`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
`;

const StyledImagesContainer = styled.div`
    width: 90%;
    margin: 20px auto;
`;

const StyledImageItem = styled.img`
    display: block;
    width: 100%;
    margin: 10px auto;

    &::after {
        display: block;
        content: " ";
        width: 10px;
        height: 4px;
        background-color: red;
    }
`;

const StyledItemSeperator = styled.div`
    display: block;
    width: 10%;
    height: 5px;
    margin 20px auto;
    background-color: #dcdcdc;
    border-radius: 10px;
`;

class ProjectDetail extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            project: this.getProjectData(props.match.params.slug)
        }
    }

    getProjectData(slug) {
        let projectData = ProjectDetailData.filter(row => row.id === slug);
        return projectData.length > 0 ? projectData[0] : false;
    }
    
    render() {

        if(this.state.project === false) {
            window.location = '/';
            return;
        }

        const awardsData = this.state.project.awards.map(row => {
            return { primary: row.name, secondary: row.category }
        });

        const imageList = this.state.project.images.map((image,index) => {
            return <div key={index}>
            <StyledImageItem src={ require(`../../assets/images/projects/big/${this.state.project.id}/${image}`) }></StyledImageItem>
            <StyledItemSeperator></StyledItemSeperator>
            </div>;
        });

        return (
        <div className="page-row">
            <div className="page-column left-content">
                <Title primary={this.state.project.brand} secondary={this.state.project.title} />
                <Text text={this.state.project.description} />
                {awardsData.length > 0 ? <List title={ 'WON AWARDS'} data={awardsData} /> : false}
            </div>
            <div className="page-column right">
                { this.state.project.video !== "" ? <div>
                    <StyledYoutubeEmbedContainer>
                        <StyledYoutubeEmbed width="100%" height="auto" frameborder="0" allowfullscreen="" src={'https://www.youtube.com/embed/' + this.state.project.video + '?rel=0&showinfo=0'}></StyledYoutubeEmbed>
                    </StyledYoutubeEmbedContainer>
                </div>
                : ""
                }
                { this.state.project.images.length > 0 ?
                <StyledImagesContainer>
                    { imageList }
                </StyledImagesContainer>
                : ""
                }
            </div>
        </div>
        );
    }
}

export default ProjectDetail;