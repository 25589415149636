import GuinnessLogo from '../../assets/images/awards/guinness.png';
import GuinnessLogo2x from '../../assets/images/awards/guinness@2x.png';
import KristalElmaLogo from '../../assets/images/awards/kristal-elma-logo.png';
import KristalElmaLogo2x from '../../assets/images/awards/kristal-elma-logo@2x.png';
import MixxAwardsLogo from '../../assets/images/awards/mixx-awards-logo.png';
import MixxAwardsLogo2x from '../../assets/images/awards/mixx-awards-logo@2x.png';
import DigitalAgeLogo from '../../assets/images/awards/digitalage-logo.png';
import DigitalAgeLogo2x from '../../assets/images/awards/digitalage-logo@2x.png';
import MarTechLogo from '../../assets/images/awards/martech-logo.png';
import MarTechLogo2x from '../../assets/images/awards/martech-logo@2x.png';
import FelisLogo from '../../assets/images/awards/felis-logo.png';
import FelisLogo2x from '../../assets/images/awards/felis-logo@2x.png';
import EffieLogo from '../../assets/images/awards/effie-logo.png';
import EffieLogo2x from '../../assets/images/awards/effie-logo@2x.png';

const AwardsData = [
    {
        project: {
            brand: "Nescafe",
            title: "Muhabbet Rekoru"
        },
        award: {
            name: "Guinness World Record",
            category: "Longest marathon hosting an internet radio talk show",
            logo: {
                src: GuinnessLogo,
                srcSet: GuinnessLogo2x + ' 2x'
            }
        }
    },
    {
        project: {
            brand: "Nescafe",
            title: "Muhabbet Rekoru"
        },
        award: {
            name: "24. Kristal Elma Awards",
            category: "Grand Prize in Digital",
            logo: {
                src: KristalElmaLogo,
                srcSet: KristalElmaLogo2x + ' 2x'
            }
        }
    },
    {
        project: {
            brand: "Nescafe",
            title: "Muhabbet Rekoru"
        },
        award: {
            name: "24. Kristal Elma Awards",
            category: "First Prize in Digital (Product Social Media)",
            logo: {
                src: KristalElmaLogo,
                srcSet: KristalElmaLogo2x + ' 2x'
            }
        }
    },
    {
        project: {
            brand: "Turkcell",
            title: "Takeover Banner"
        },
        award: {
            name: "24. Kristal Elma Awards",
            category: "Banner, Rich Media (Second in Category)",
            logo: {
                src: KristalElmaLogo,
                srcSet: KristalElmaLogo2x + ' 2x'
            }
        }
    },
    {
        project: {
            brand: "Nescafe",
            title: "Muhabbet Rekoru"
        },
        award: {
            name: "24. Kristal Elma Awards",
            category: "First Prize in Digital (Viral Campaign)",
            logo: {
                src: KristalElmaLogo,
                srcSet: KristalElmaLogo2x + ' 2x'
            }
        }
    },
    {
        project: {
            brand: "Turkcell",
            title: "Bahane Bulucu"
        },
        award: {
            name: "24. Kristal Elma Awards",
            category: "Banner, Rich Media (Second in Category)",
            logo: {
                src: KristalElmaLogo,
                srcSet: KristalElmaLogo2x + ' 2x'
            }
        }
    },
    {
        project: {
            brand: "Axe",
            title: "Axe Seni Uzaya Gönderiyor"
        },
        award: {
            name: "25. Kristal Elma Awards",
            category: "Bronze Apple (Digital)",
            logo: {
                src: KristalElmaLogo,
                srcSet: KristalElmaLogo2x + ' 2x'
            }
        }
    },
    {
        project: {
            brand: "Nescafe",
            title: "Bi' Kahveyle Başlar"
        },
        award: {
            name: "25. Kristal Elma Awards",
            category: "Bronze Apple (Digital)",
            logo: {
                src: KristalElmaLogo,
                srcSet: KristalElmaLogo2x + ' 2x'
            }
        }
    },
    {
        project: {
            brand: "Cornetto",
            title: "Aşkını Duymayan Kalmasın"
        },
        award: {
            name: "25. Kristal Elma Awards",
            category: "Silver Apple (Digital)",
            logo: {
                src: KristalElmaLogo,
                srcSet: KristalElmaLogo2x + ' 2x'
            }
        }
    },
    {
        project: {
            brand: "Cornetto",
            title: "Aşkını Duymayan Kalmasın"
        },
        award: {
            name: "25. Kristal Elma",
            category: "Bronze Apple (Social Media Applications)",
            logo: {
                src: KristalElmaLogo,
                srcSet: KristalElmaLogo2x + ' 2x'
            }
        }
    },
    {
        project: {
            brand: "Cornetto",
            title: "Aşkını Duymayan Kalmasın"
        },
        award: {
            name: "25. Kristal Elma",
            category: "Bronze Apple (Best Use of Two Screens)",
            logo: {
                src: KristalElmaLogo,
                srcSet: KristalElmaLogo2x + ' 2x'
            }
        }
    },
    {
        project: {
            brand: "Nescafe",
            title: "Bi' Kahveyle Başlar"
        },
        award: {
            name: "25. Kristal Elma",
            category: "Bronze Apple (The Most Creative Social Media Platform)",
            logo: {
                src: KristalElmaLogo,
                srcSet: KristalElmaLogo2x + ' 2x'
            }
        }
    },
    {
        project: {
            brand: "Lösev",
            title: "Gülüşünde Hayat Var"
        },
        award: {
            name: "Kristal Elma 2017",
            category: "Crystal Apple (Radio)",
            logo: {
                src: KristalElmaLogo,
                srcSet: KristalElmaLogo2x + ' 2x'
            }
        }
    },
    {
        project: {
            brand: "Lösev",
            title: "Gülüşünde Hayat Var"
        },
        award: {
            name: "Kristal Elma 2017",
            category: "Silver Apple (The Most Creative Social Responsibility Campaign)",
            logo: {
                src: KristalElmaLogo,
                srcSet: KristalElmaLogo2x + ' 2x'
            }
        }
    },
    {
        project: {
            brand: "GittiGidiyor",
            title: "Google Ads"
        },
        award: {
            name: "MarTech Awards 2019",
            category: "Best MarTech Ecosystem Use",
            logo: {
                src: MarTechLogo,
                srcSet: MarTechLogo2x + ' 2x'
            }
        }
    },
    {
        project: {
            brand: "Lösev",
            title: "Gülüşünde Hayat Var"
        },
        award: {
            name: "Felis 2017",
            category: "Usage and Integration Categories - Radio / Audio",
            logo: {
                src: FelisLogo,
                srcSet: FelisLogo2x + ' 2x'
            }
        }
    },
    {
        project: {
            brand: "Lösev",
            title: "Gülüşünde Hayat Var"
        },
        award: {
            name: "Felis 2017",
            category: "Civil Society Organizations Projects - Health Area",
            logo: {
                src: FelisLogo,
                srcSet: FelisLogo2x + ' 2x'
            }
        }
    },
    {
        project: {
            brand: "Lösev",
            title: "Gülüşünde Hayat Var"
        },
        award: {
            name: "Felis 2017",
            category: "Best Radio Usage",
            logo: {
                src: FelisLogo,
                srcSet: FelisLogo2x + ' 2x'
            }
        }
    },
    {
        project: {
            brand: "Turkcell",
            title: "Tweet Sende"
        },
        award: {
            name: "Mixx Awards Turkey 2012",
            category: "Bronze Mixx (Social Marketing)",
            logo: {
                src: MixxAwardsLogo,
                srcSet: MixxAwardsLogo2x + ' 2x'
            }
        }
    },
    {
        project: {
            brand: "Turkcell",
            title: "Hayat Paylaşınca Güzel"
        },
        award: {
            name: "Mixx Awards Turkey 2012",
            category: "Bronze Mixx (Microsite)",
            logo: {
                src: MixxAwardsLogo,
                srcSet: MixxAwardsLogo2x + ' 2x'
            }
        }
    },
    {
        project: {
            brand: "Turkcell",
            title: "Kedi Pati"
        },
        award: {
            name: "Mixx Awards Turkey 2012",
            category: "Gold Mixx (Product introduction)",
            logo: {
                src: MixxAwardsLogo,
                srcSet: MixxAwardsLogo2x + ' 2x'
            }
        }
    },
    {
        project: {
            brand: "Turkcell",
            title: "Bahane Bulucu"
        },
        award: {
            name: "Mixx Awards Turkey 2012",
            category: "Bronze Mixx (Application)",
            logo: {
                src: MixxAwardsLogo,
                srcSet: MixxAwardsLogo2x + ' 2x'
            }
        }
    },
    {
        project: {
            brand: "Cornetto",
            title: "Aşkını Duymayan Kalmasın"
        },
        award: {
            name: "Mixx Awards Turkey 2012",
            category: "Gold Mixx (Venue Based Advertising)",
            logo: {
                src: MixxAwardsLogo,
                srcSet: MixxAwardsLogo2x + ' 2x'
            }
        }
    },
    {
        project: {
            brand: "Turkcell",
            title: "Takeover Banner"
        },
        award: {
            name: "DigitalAge Awards Turkey 2011",
            category: "The Most Creative Banners",
            logo: {
                src: DigitalAgeLogo,
                srcSet: DigitalAgeLogo2x + ' 2x'
            }
        }
    },
    {
        project: {
            brand: "Nescafe",
            title: "Muhabbet Rekoru"
        },
        award: {
            name: "DigitalAge Awards Turkey 2012",
            category: "Most Creative Branded Content Usage (Category Winner)",
            logo: {
                src: DigitalAgeLogo,
                srcSet: DigitalAgeLogo2x + ' 2x'
            }
        }
    },
    {
        project: {
            brand: "Dove",
            title: "Kuru Saçlara Elveda"
        },
        award: {
            name: "DigitalAge Awards Turkey 2012",
            category: "Most Creative Digital (Dijital Out of Home)",
            logo: {
                src: DigitalAgeLogo,
                srcSet: DigitalAgeLogo2x + ' 2x'
            }
        }
    },
    {
        project: {
            brand: "Dove",
            title: "Nil'le Düet"
        },
        award: {
            name: "DigitalAge Awards Turkey 2012",
            category: "Most Creative Music / Sound Design",
            logo: {
                src: DigitalAgeLogo,
                srcSet: DigitalAgeLogo2x + ' 2x'
            }
        }
    },
    {
        project: {
            brand: "Cornetto",
            title: "Aşkını Duymayan Kalmasın"
        },
        award: {
            name: "DigitalAge Awards Turkey 2012",
            category: "The Most Creative Mobile Ad",
            logo: {
                src: DigitalAgeLogo,
                srcSet: DigitalAgeLogo2x + ' 2x'
            }
        }
    },
    {
        project: {
            brand: "Lösev",
            title: "Gülüşünde Hayat Var"
        },
        award: {
            name: "Effie 2018",
            category: "Bronze (Civil Society Organizations)",
            logo: {
                src: EffieLogo,
                srcSet: EffieLogo2x + ' 2x'
            }
        }
    },
];

export default AwardsData;