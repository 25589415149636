import React from 'react';
import styled from 'styled-components';
import responsive from '../../../config/responsive';

const StyledText = styled.div`
    font-weight: 400;
    font-size: ${responsive.font.default.size3};
    padding: 12px 0 24px 0;

    @media (max-width: ${responsive.size.desktop.small}) {
        text-align: center;
    }

    @media (max-width: ${responsive.size.tablet.landscape}) {
        font-size: ${responsive.font.mobile.size3}
    }
`;

class Text extends React.Component {
    render() {
        return (
        <StyledText>{ this.props.text }</StyledText>
        )
    }
}

export default Text;