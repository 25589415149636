import React, { lazy, Suspense } from 'react';
import styled from 'styled-components';
import ExperiencesData from './data.js';

const Title = lazy(() => import('../../components/atoms/Title/Title'));
const Text = lazy(() => import('../../components/atoms/Text/Text'));
const Button = lazy(() => import('../../components/atoms/Button/Button'));
const TimelineContainer = lazy(() => import('../../components/organisms/Timeline'));

const StyledTable = styled.div`
    display: table;
    margin: 0 auto;
`;


class Experience extends React.Component {
    
    render() {

        let renderedExperiencesData = ExperiencesData.map((experience,index) => {
            return(
                <TimelineContainer key={index}
                logo = {experience.company.image.src} 
                logoSet = {experience.company.image.srcSet}
                link = {experience.company.url}
                PrimaryText = {experience.position}
                SecondaryText = {experience.company.name}
                ExtraText = {experience.date}
                />
            );
        });

        return (
        <Suspense fallback={""}>
            <div className="page-row">
                <div className="page-column left">
                        <Title primary="İbrahim Hızlıoğlu" secondary="Software Consultant" />
                        <Text text="I have been working in Software Developer, Technical Lead and Software Consultant positions in Istanbul, Turkey since 2008." />
                        <Button text="Go to Linkedin" href="http://www.linkedin.com/in/ibo" target="_blank" />
                </div>
                <div className="page-column right">
                    <StyledTable>
                        {renderedExperiencesData}
                    </StyledTable>
                </div>
            </div>
        </Suspense>
        );
    }
}

export default Experience;