import React from 'react';
import styled from 'styled-components';
import responsive from '../../../config/responsive';

const StyledTitlePrimary = styled.div`
    font-weight: 700;
    font-size: ${responsive.font.default.size1};

    @media (max-width: ${responsive.size.desktop.small}) {
        text-align: center;
    }

    @media (max-width: ${responsive.size.tablet.landscape}) {
        font-size: ${responsive.font.mobile.size1}
    }
`;

const StyledTitleSecondary = styled.div`
    font-weight: 500;
    font-size: ${responsive.font.default.size2};
    margin-top: -0.2em;

    @media (max-width: ${responsive.size.tablet.landscape}) {
        font-size: ${responsive.font.mobile.size2}
    }
`;

class Title extends React.Component {
    render() {
        return (
            <StyledTitlePrimary
            >{this.props.primary} <StyledTitleSecondary>{this.props.secondary}</StyledTitleSecondary>
            </StyledTitlePrimary>
        )
    }
}

export default Title;