import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Title from '../../components/atoms/Title/Title';
import Text from '../../components/atoms/Text/Text';
import ProjectsData from './data.js';
import responsive from '../../config/responsive';

const StyledProjectsWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

const StyledProjectLink = styled(Link)`
    position: relative;
    flex: 40%;
    margin: 10px;

    &:hover div {
        opacity: 0.90;
    }

    @media (max-width: ${responsive.size.tablet.landscape}) {
        flex: 100%;
    }
`;

const StyledImage = styled.img`
    display: block;
    width: 100%;
    margin: 0 auto;
`;

const StyledProjectHover = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #121212;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #FFF;
    text-decoration: none;
    font-size: 32px;
    font-weight: 400;
    opacity: 0;
    transition: opacity 0.4s ease-in;

    b {
        display: block;
        width: 100%;
        text-align: center;
        font-weight: 600;
        margin-bottom: 4px;
    }

    span {
        display: block;
        width: 100%;
        text-align: center;
    }

    @media (max-width: ${responsive.size.tablet.landscape}) {
        font-size: 18px;
    }
`;

class Projects extends React.Component {
    
    render() {

        let renderedProjectsData = ProjectsData.map((row,index) => {
            return(
                <StyledProjectLink key={index} to={'/project/' + row.id}>
                    <StyledImage src={row.image.src} srcSet={row.image.srcset} />
                    <StyledProjectHover>
                        <b>{row.brand}</b>
                        <span>{row.project}</span>
                    </StyledProjectHover>
                </StyledProjectLink>
            );
        });

        return (
        <div className="page-row">
            <div className="page-column left">
                <Title primary="Featured" secondary="Projects" />
                <Text text="This page contains some of the software projects I have developed since 2008. You can review the project details for more information." />
            </div>
            <div className="page-column right">
                <StyledProjectsWrapper>
                    { renderedProjectsData }
                </StyledProjectsWrapper>
            </div>
        </div>
        );
    }
}

export default Projects;