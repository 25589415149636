import NescafeMuhabbetRekoru from '../../assets/images/projects/thumbnail/nescafe-muhabbet-rekoru.jpg';
import NescafeMuhabbetRekoru2x from '../../assets/images/projects/thumbnail/nescafe-muhabbet-rekoru@2x.jpg';
import CornettoAskiniDuymayanKalmasin from '../../assets/images/projects/thumbnail/cornetto-askini-duymayan-kalmasin.jpg';
import CornettoAskiniDuymayanKalmasin2x from '../../assets/images/projects/thumbnail/cornetto-askini-duymayan-kalmasin@2x.jpg';
import RefreshChallenge from '../../assets/images/projects/thumbnail/lipton-refresh-challenge.jpg';
import RefreshChallenge2x from '../../assets/images/projects/thumbnail/lipton-refresh-challenge@2x.jpg';
import GittiGidiyorAds from '../../assets/images/projects/thumbnail/gittigidiyor-reklam-paketi.jpg';
import GittiGidiyorAds2x from '../../assets/images/projects/thumbnail/gittigidiyor-reklam-paketi@2x.jpg';
import Turkcell4Ceker from '../../assets/images/projects/thumbnail/turkcell-4-ceker.jpg';
import Turkcell4Ceker2x from '../../assets/images/projects/thumbnail/turkcell-4-ceker@2x.jpg';
import NilleDuet from '../../assets/images/projects/thumbnail/nille-duet.jpg';
import NilleDuet2x from '../../assets/images/projects/thumbnail/nille-duet@2x.jpg';
import GonullerinMeydanSavasi from '../../assets/images/projects/thumbnail/pepsi-gonullerin-meydan-savasi.jpg';
import GonullerinMeydanSavasi2x from '../../assets/images/projects/thumbnail/pepsi-gonullerin-meydan-savasi@2x.jpg';
import TurpMobileApp from '../../assets/images/projects/thumbnail/turp-app.jpg';
import TurpMobileApp2x from '../../assets/images/projects/thumbnail/turp-app@2x.jpg';
import DoritosComTr from '../../assets/images/projects/thumbnail/doritos-com-tr.jpg';
import DoritosComTr2x from '../../assets/images/projects/thumbnail/doritos-com-tr@2x.jpg';
import PepsiComTr from '../../assets/images/projects/thumbnail/pepsi-com-tr.jpg';
import PepsiComTr2x from '../../assets/images/projects/thumbnail/pepsi-com-tr@2x.jpg';
import AkmerkezComTr from '../../assets/images/projects/thumbnail/akmerkez-com-tr.jpg';
import AkmerkezComTr2x from '../../assets/images/projects/thumbnail/akmerkez-com-tr@2x.jpg';
import ClearComTr from '../../assets/images/projects/thumbnail/clear-com-tr.jpg';
import ClearComTr2x from '../../assets/images/projects/thumbnail/clear-com-tr@2x.jpg';
import TurkcellHayatPaylasincaGuzel from '../../assets/images/projects/thumbnail/turkcell-hayat-paylasinca-guzel.jpg';
import TurkcellHayatPaylasincaGuzel2x from '../../assets/images/projects/thumbnail/turkcell-hayat-paylasinca-guzel@2x.jpg';
import LosevGulusundeHayatVar from '../../assets/images/projects/thumbnail/losev-gulusunde-hayat-var.jpg';
import LosevGulusundeHayatVar2x from '../../assets/images/projects/thumbnail/losev-gulusunde-hayat-var@2x.jpg';

const ProjectsData = [
    {
        id: "muhabbet-rekoru",
        brand: "NESCAFE",
        project: "Muhabbet Rekoru",
        image: {
            src: NescafeMuhabbetRekoru,
            srcset: NescafeMuhabbetRekoru2x + ' 2x'
        }
    },
    {
        id: "cornetto-askini-duymayan-kalmasin",
        brand: "CORNETTO",
        project: "Aşkını Duymayan Kalmasın",
        image: {
            src: CornettoAskiniDuymayanKalmasin,
            srcset: CornettoAskiniDuymayanKalmasin2x + ' 2x'
        }
    },
    {
        id: "lipton-ice-tea-refresh-challenge",
        brand: "LIPTON ICE TEA",
        project: "Refresh Challenge",
        image: {
            src: RefreshChallenge,
            srcset: RefreshChallenge2x + ' 2x'
        }
    },
    {
        id: "turkcell-4-ceker",
        brand: "TURKCELL",
        project: "Takeover Banner",
        image: {
            src: Turkcell4Ceker,
            srcset: Turkcell4Ceker2x + ' 2x'
        }
    },
    {
        id: "gittigidiyor-google-reklam-paketi",
        brand: "EBAY / GİTTİGİDİYOR",
        project: "Google Ads Integration",
        image: {
            src: GittiGidiyorAds,
            srcset: GittiGidiyorAds2x + ' 2x'
        }
    },
    {
        id: "turp-mobile-app",
        brand: "TURP",
        project: "Mobile App",
        image: {
            src: TurpMobileApp,
            srcset: TurpMobileApp2x + ' 2x'
        }
    },
    {
        id: "dove-nille-duet",
        brand: "DOVE",
        project: "Nil'le Düet",
        image: {
            src: NilleDuet,
            srcset: NilleDuet2x + ' 2x'
        }
    },
    {
        id: "losev-gulusunde-hayat-var",
        brand: "LÖSEV",
        project: "Gülüşünde Hayat Var",
        image: {
            src: LosevGulusundeHayatVar,
            srcset: LosevGulusundeHayatVar2x + ' 2x'
        }
    },
    {
        id: "pepsi-gonullerin-meydan-savasi",
        brand: "PEPSI",
        project: "Gönüllerin Meydan Savaşı",
        image: {
            src: GonullerinMeydanSavasi,
            srcset: GonullerinMeydanSavasi2x + ' 2x'
        }
    },
    {
        id: "doritos-com-tr",
        brand: "DORITOS",
        project: "Website for Turkey",
        image: {
            src: DoritosComTr,
            srcset: DoritosComTr2x + ' 2x'
        }
    },
    {
        id: "pepsi-com-tr",
        brand: "PEPSI CO",
        project: "Website for Turkey",
        image: {
            src: PepsiComTr,
            srcset: PepsiComTr2x + ' 2x'
        }
    },
    {
        id: "akmerkez-com-tr",
        brand: "AKMERKEZ",
        project: "Web Site",
        image: {
            src: AkmerkezComTr,
            srcset: AkmerkezComTr2x + ' 2x'
        }
    },
    {
        id: "clear-com-tr",
        brand: "CLEAR",
        project: "Website for Turkey",
        image: {
            src: ClearComTr,
            srcset: ClearComTr2x + ' 2x'
        }
    },
    {
        id: "turkcell-hayat-paylasinca-guzel",
        brand: "TURKCELL",
        project: "Hayat Paylaşınca Güzel",
        image: {
            src: TurkcellHayatPaylasincaGuzel,
            srcset: TurkcellHayatPaylasincaGuzel2x + ' 2x'
        }
    }
];

export default ProjectsData;