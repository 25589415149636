
const ProjectsData = [
    {
        id: "muhabbet-rekoru",
        brand: "NESCAFE",
        title: "Muhabbet Rekoru",
        description: "Developed for Rabarba Digital Agency in 2012. My role was Back-end Developer. Used technologies: Php, CodeIgniter, MySQL, Database Scaling (cluster) and performance improvement.",
        video: "5AOBWFUG3WE",
        images: [],
        awards: [
            { name: "Guinness" , category: "Guinness World Chat Record (2012)" },
            { name: "24. Kristal Elma" , category: "Grand Prize in Digital" },
            { name: "24. Kristal Elma" , category: "First Prize in Digital (Product Social Media)" },
            { name: "24. Kristal Elma" , category: "First Prize in Digital (Viral Campaign)" },
            { name: "Digital Age 2012" , category: "Most Creative Branded Content Usage (Category Winner)" },
            { name: "Digital Age 2012" , category: "The Most Creative Digital Centered Campaign" },
        ]
    },
    {
        id: "cornetto-askini-duymayan-kalmasin",
        brand: "CORNETTO",
        title: "Aşkını Duymayan Kalmasın",
        description: "Developed for Rabarba Digital Agency in 2012. My role was Back-end Developer. Used technologies: Php, MySQL, JavaScript and 3rd party API Integration (SMS, IVR, Facebook)",
        video: "Hzikn8krU9M",
        images: ["1.jpg","2.jpg","3.jpg","4.jpg","5.jpg","6.jpg","7.jpg","8.jpg"],
        awards: [
            { name: "Mixx Awards Turkey 2012" , category: "Gold Mixx (Venue Based Advertising)" },
            { name: "25. Kristal Elma" , category: "Bronze (Social Media Applications)" },
            { name: "25. Kristal Elma" , category: "Bronze (Best Use of Two Screens)" },
            { name: "25. Kristal Elma" , category: "Silver (Digital)" },
            { name: "Digital Age 2012" , category: "The Most Creative Mobile Ad" },
        ]
    },
    {
        id: "lipton-ice-tea-refresh-challenge",
        brand: "LIPTON ICE TEA",
        title: "Refresh Challenge",
        description: "Developed for Rabarba Digital Agency in 2013. My role was Back-end Developer. Used technologies: NodeJS, SocketIO, MongoDB, WebSocket, Php, MySQL, Facebook API",
        video: "wORjlDpoKVI",
        images: ["1.jpg","2.jpg","3.jpg","4.jpg","5.jpg","6.jpg","7.jpg","8.jpg"],
        awards: []
    },
    {
        id: "gittigidiyor-google-reklam-paketi",
        brand: "GİTTİGİDİYOR.COM",
        title: "Google Ads Integration",
        description: "Developed for GittiGidiyor.com (eBay Turkey) in 2018. My role was Full Stack Developer. Used technologies: Java, Spring Boot, Php, MySQL, Zend Framework, JavaScript and Microservices",
        video: "",
        images: ["1.jpg","2.jpg","3.jpg","4.jpg","5.jpg"],
        awards: [
            { name: "MarTech Awards 2019" , category: "Best MarTech Ecosystem Use" }
        ]
    },
    {
        id: "turp-mobile-app",
        brand: "TURP",
        title: "Mobile App",
        description: "Developed for Turp App in 2015. My role was Full-Stack Developer and Software Consultant. Used technologies: Php, Laravel, MySQL, AngularJS and Video Chat API",
        video: "",
        images: ["1.jpg"],
        awards: []
    },
    {
        id: "turkcell-4-ceker",
        brand: "TURKCELL",
        title: "Takeover Banner",
        description: "Developed for Rabarba Digital Agency in 2011. My role was Back-end Developer. Used technologies: Php, CodeIgniter, MySQL, JavaScript and SMS & IVR Integration",
        video: "YJYoEeVnHy4",
        images: [],
        awards: [
            { name: "Digital Age 2011" , category: "The Most Creative Banners" },
            { name: "24. Kristal Elma" , category: "Banner, Rich Media (Second in the category)" }
        ]
    },
    {
        id: "pepsi-gonullerin-meydan-savasi",
        brand: "PEPSI",
        title: "Gönüllerin Meydan Savaşı",
        description: "Developed for Rabarba Digital Agency in 2013. My role was Back-end Developer. Used technologies: Php, CodeIgniter, MySQL, Twitter API",
        video: "LYY7BAzzixM",
        images: ["1.jpg","2.jpg","3.jpg","4.jpg","5.jpg","6.jpg","7.jpg"],
        awards: []
    },
    {
        id: "dove-nille-duet",
        brand: "DOVE",
        title: "Nil'le Düet",
        description: "Developed for Rabarba Digital Agency in 2011. My role was Back-end Developer. Used technologies: Php, CodeIgniter, MySQL, JavaScript and SMS & IVR Integration",
        video: "",
images: ["1.jpg","2.jpg","3.jpg","4.jpg","5.jpg","6.jpg","7.jpg","8.jpg","9.jpg"],
        awards: [
            { name: "Digital Age 2012" , category: "Most Creative Music / Sound Design" }
        ]
    },
    {
        id: "doritos-com-tr",
        brand: "DORITOS",
        title: "Website for Turkey",
        description: "Developed for Rafineri Ad Agency in 2017. My role was Technical Lead and Software Developer.",
        video: "",
        images: ["1.gif","2.jpg"],
        awards: []
    },
    {
        id: "pepsi-com-tr",
        brand: "PEPSI CO",
        title: "Website for Turkey",
        description: "Developed for Rabarba Digital Agency in 2012. My role was Back-end Developer. Used technologies: Php, MySQL, Zend Framework",
        video: "",
        images: ["1.jpg"],
        awards: []
    },
    {
        id: "akmerkez-com-tr",
        brand: "AKMERKEZ",
        title: "Web Site",
        description: "Developed for Rafineri Ad Agency in 2015. My role was Technical Lead and Software Developer. Used technologies: Php, Laravel, MySQL, JavaScript",
        video: "",
        images: ["1.jpg","2.jpg","3.jpg","4.jpg","5.jpg","6.jpg"],
        awards: []
    },
    {
        id: "clear-com-tr",
        brand: "CLEAR",
        title: "Website for Turkey",
        description: "Developed for Rabarba Digital Agency in 2012. My role was Back-end Developer. Used technologies: Php, MySQL, CodeIgniter",
        video: "",
        images: ["1.jpg","2.jpg","3.jpg","4.jpg"],
        awards: []
    },
    {
        id: "turkcell-hayat-paylasinca-guzel",
        brand: "TURKCELL",
        title: "Hayat Paylaşınca Güzel",
        description: "Developed for Rabarba Digital Agency in 2012. My role was Back-end Developer. Used technologies: Php, MySQL, CodeIgniter and Facebook API Integration",
        video: "",
        images: ["1.jpg","2.jpg","3.jpg","4.jpg","5.jpg","6.jpg","7.jpg","8.jpg","9.jpg","10.jpg","11.jpg","12.jpg","13.jpg"],
        awards: [
            { name: "Mixx Awards Turkey 2012" , category: "Bronze Mixx (Microsite)" }
        ]
    },
    {
        id: "losev-gulusunde-hayat-var",
        brand: "LÖSEV",
        title: "Gülüşünde Hayat Var",
        description: "Developed for Rafineri Ad Agency in 2017. My role was Technical Lead and Software Developer.",
        video: "SZj9xkEs1Q0",
        images: ["1.jpg","2.jpg"],
        awards: [
            { name: "Kristal Elma 2017" , category: "Crystal Apple (Radio)" },
            { name: "Kristal Elma 2017" , category: "Silver Apple (The Most Creative Social Responsibility Campaign)" },
            { name: "Felis 2017" , category: "Best Radio Usage" },
            { name: "Felis 2017" , category: "Usage and Integration Categories - Radio / Audio" },
            { name: "Felis 2017" , category: "Civil Society Organizations Projects - Health Area" },
            { name: "Effie 2018" , category: "Bronze (Civil Society Organizations)" }
        ]
    }
];

export default ProjectsData;