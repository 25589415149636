import React, { lazy, Suspense } from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { withRouter } from "react-router";
import responsive from '../../config/responsive';

const BtnClose = lazy(() => import('../../components/atoms/Button/Close'));

const StyledHeader = styled.div`
    position:fixed;
    display: flex;
    top:0;
    left:0;
    width:100%;
    z-index:999;
    background-color: #FFF;
`;

const StyledBtnContainer = styled.div`
    display: flex;
    flex:10%;
    padding: 20px 40px;
    align-items: center;
    @media (max-width: ${responsive.size.tablet.landscape}) {
        padding: 10px 20px;
    }
`;

const StyledBtn = styled.a`
    display: inline-block;
    width: 60px;
    padding: 10px 0;
    cursor: pointer;
    z-index: 999;

    span {
        display: block;
        width: 100%;
        height: 6px;
        background-color: ${props => props.openNavigation ? '#FFFFFF' : '#121212'};
        transition: all 0.5s;
    }

    span:nth-child(2) {
        width: 70%;
        margin: 6px 0;
    }

    span:nth-child(3) {
        width: 40%;
    }

    &:hover span:nth-child(1) {
        width: 60%;
    }

    &:hover span:nth-child(2) {
        width: 80%;
    }

    &:hover span:nth-child(3) {
        width: 100%;
    }
`;

const StyledTitleContainer = styled.div`
    font-size: 46px;
    font-weight: 600;
    flex: 89%;
    text-align: right;
    padding: 20px 40px;

    @media (max-width: ${responsive.size.tablet.landscape}) {
        display: none;
    }
`;

const StyledPageNumber = styled.span`
    font-weight: 100;
`;

const StyledNavigationContainer = styled.div`
    width: 40%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    background-color: #121212;
    display: block;
    padding-top: 100px;
    transition: all 0.2s ease-in;
    transform: translateX(${props => props.openNavigation ? '0' : '-120%'});

    @media (max-width: ${responsive.size.tablet.landscape}) {
        width: 100%;
        padding-top: 80px;
    }
`;

const StyledNavigationLink = styled(NavLink)`
    display: table;
    color: #FFF;
    font-size: ${responsive.font.default.size3};
    margin: 0px 30px;
    padding: 10px;
    cursor: pointer;
    text-decoration: none;

    &:hover:not(.active) {
        background-color: #FFF;
        color: #121212;
    }

    &.active {
        cursor: default;
        color: #969696;
    }

    span {
        font-weight: 600;
        padding-right: 10px;
    }

    @media (max-width: ${responsive.size.tablet.landscape}) {
        width: 100%;
        font-size: ${responsive.font.mobile.size4};
        margin: 0px 10px;
    }
`;

const StyledBackButton = styled.a`
`;

const StyledBackButtonImage = styled.img`
    display: block;
    height: 40px;
    cursor: pointer;
`;

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openNavigation: false,
            navigationItems: [
                { number:"01", link: "/experience", title: "EXPERIENCE" },
                { number:"02", link: "/book", title: "FACEBOOK PROGRAMMING" },
                { number:"03", link: "/awards", title: "AWARDS" },
                { number:"04", link: "/projects", title: "PROJECTS" },
                { number:"05", link: "/guinness-world-record", title: "GUINNESS WORLD RECORD" }
            ]
        };
    }

    setNavigationStatus(status) {
        this.setState( { openNavigation : status } );
    }

    openNavigation() {
        this.setNavigationStatus(true);
    }

    closeNavigation() {
        this.setNavigationStatus(false);
    }

    goBack() {
        const { history } = this.props;
        if(history.action === "PUSH") {
            history.goBack();
        } else {
            history.push('/');
        }
    }

    getActivePageTitle() {
        const { location } = this.props;
        const activePageObject = this.state.navigationItems.filter(row => row.link === location.pathname);
        return activePageObject.length > 0 ? activePageObject[0] : { number: "PROJECT", title: "ABOUT THE" };
    }

    render() {

        const hasProjectDetailPage = () => {
            const { location } = this.props;
            return location.pathname.split('/project/').length > 1 ? true : false;
        };

        const renderedNavigation = this.state.navigationItems.map((row,index) => {
            return(
            <StyledNavigationLink key={index} to={row.link} onClick={() => this.closeNavigation()}><span>0{index + 1}</span>{row.title}</StyledNavigationLink>
            );
        });

        const activePage = this.getActivePageTitle();

        return(
            <Suspense fallback={""}>
                <StyledHeader>
                    <StyledBtnContainer>
                        {hasProjectDetailPage() ?
                        <StyledBackButton onClick={() => this.goBack()}>
                            <BtnClose />
                        </StyledBackButton>
                        :
                        <StyledBtn onClick={() => this.setState({ openNavigation: !this.state.openNavigation })} openNavigation={this.state.openNavigation}>
                        <span></span>
                        <span></span>
                        <span></span>
                        </StyledBtn> 
                        }
                    </StyledBtnContainer>
                    <StyledTitleContainer>
                    {activePage.title} <StyledPageNumber>{activePage.number}</StyledPageNumber>
                    </StyledTitleContainer>
                    <StyledNavigationContainer openNavigation={this.state.openNavigation}>
                    {renderedNavigation}
                    </StyledNavigationContainer>
                </StyledHeader>
            </Suspense>
        )
    }
}

export default withRouter(Header);