import Getir from '../../assets/images/experience/getir.png';
import Getir2X from '../../assets/images/experience/getir@2x.png';
import CodeSection from '../../assets/images/experience/codesection.png';
import CodeSection2X from '../../assets/images/experience/codesection@2x.png';
import BundleNews from '../../assets/images/experience/bundle-news.png';
import BundleNews2X from '../../assets/images/experience/bundle-news@2x.png';
import eBay from '../../assets/images/experience/ebay.png';
import eBay2X from '../../assets/images/experience/ebay@2x.png';
import rafineri from '../../assets/images/experience/rafineri.png';
import rafineri2X from '../../assets/images/experience/rafineri@2x.png';
import promoqube from '../../assets/images/experience/promoqube.png';
import promoqube2X from '../../assets/images/experience/promoqube@2x.png';
import rabarba from '../../assets/images/experience/rabarba.png';
import rabarba2X from '../../assets/images/experience/rabarba@2x.png';
import pure from '../../assets/images/experience/pure.png';
import pure2X from '../../assets/images/experience/pure@2x.png';
import d4d from '../../assets/images/experience/d4d.png';
import d4d2X from '../../assets/images/experience/d4d@2x.png';
import freelancer from '../../assets/images/experience/freelancer.png';
import freelancer2X from '../../assets/images/experience/freelancer@2x.png';

const ExperiencesData = [
    {
        date: "02.2022 - Currently",
        position: "Engineering Manager",
        company: {
            name: "Getir",
            url: "https://www.getir.com",
            image: {
                src: Getir,
                srcSet: Getir2X + ' 2x'
            }
        }
    },
    {
        date: "06.2020 - 02.2022",
        position: "Founder & CTO",
        company: {
            name: "CodeSection",
            url: "https://www.codesection.io",
            image: {
                src: CodeSection,
                srcSet: CodeSection2X + ' 2x'
            }
        }
    },
    {
        date: "02.2020 - 06.2020",
        position: "Technical Lead",
        company: {
            name: "Bundle News",
            url: "https://www.bundle.app",
            image: {
                src: BundleNews,
                srcSet: BundleNews2X + ' 2x'
            }
        }
    },
    {
        date: "08.2017 - 02.2020",
        position: "Sr. Full Stack Developer",
        company: {
            name: "eBay / GittiGidiyor",
            url: "https://www.gittigidiyor.com",
            image: {
                src: eBay,
                srcSet: eBay2X + ' 2x'
            }
        }
    },
    {
        date: "06.2014 - 08.2017",
        position: "CTO",
        company: {
            name: "Rafineri",
            url: "http://www.rafineri.net",
            image: {
                src: rafineri,
                srcSet: rafineri2X + ' 2x'
            }
        }
    },
    {
        date: "02.2014 - 06.2014",
        position: "Technical Lead",
        company: {
            name: "Promoqube",
            url: "http://www.promoqube.com",
            image: {
                src: promoqube,
                srcSet: promoqube2X + ' 2x'
            }
        }
    },
    {
        date: "06.2011 - 02.2014",
        position: "Software Developer",
        company: {
            name: "Rabarba",
            url: "http://www.rabarba.com",
            image: {
                src: rabarba,
                srcSet: rabarba2X + ' 2x'
            }
        }
    },
    {
        date: "10.2009 - 06.2011",
        position: "Software Developer",
        company: {
            name: "Pure New Media",
            url: "http://www.pure.com.tr",
            image: {
                src: pure,
                srcSet: pure2X + ' 2x'
            }
        }
    },
    {
        date: "06.2009 - 10.2009",
        position: "Software Developer",
        company: {
            name: "D4D",
            url: "http://www.d4d.com.tr",
            image: {
                src: d4d,
                srcSet: d4d2X + ' 2x'
            }
        }
    },
    {
        date: "06.2005 - 06.2009",
        position: "Software Developer",
        company: {
            name: "Freelancer",
            url: "http://www.freelancer.com",
            image: {
                src: freelancer,
                srcSet: freelancer2X + ' 2x'
            }
        }
    }
];

export default ExperiencesData;
