const ResponsiveConfig = {
    size: {
        desktop: {
            small: "1279px"
        },
        tablet: {
            landscape: "1024px",
            portrait: "834px"
        },
        mobile: {
            portrait: "414px",
            landscape: "812px"
        }
    },
    font: {
        default: {
            size1: "60px",
            size2: "50px",
            size3: "34px",
            size4: "24px"
        },
        mobile: {
            size1: "40px",
            size2: "30px",
            size3: "24px",
            size4: "20px",
        }
    }
};

export default ResponsiveConfig;