import React from 'react';
import styled from 'styled-components';
import responsive from '../../../config/responsive';

const StyledContainer = styled.div`
    margin: 10px 0;
`;

const StyledTitle = styled.div`
    font-size: ${responsive.font.default.size1};
    font-weight: 600;

    @media (max-width: ${responsive.size.desktop.small}) {
        text-align: center;
    }

    @media (max-width: ${responsive.size.tablet.landscape}) {
        font-size: ${responsive.font.mobile.size1}
    }
`;

const StyledItemContainer = styled.div`
    margin: 10px auto;

    @media (max-width: ${responsive.size.desktop.small}) {
        text-align: center;
    }
`;

const StyledItem = styled.div`
    font-weight: 400;
    font-size: ${responsive.font.default.size3};
    padding: 0;
    padding: 12px 0;
    
    .primary {
        display: block;
        font-weight: 500;
    }

    .secondary {
        display: block;
        font-weight: 300;
        font-size: ${responsive.font.default.size4};

        @media (max-width: ${responsive.size.tablet.landscape}) {
            font-size: ${responsive.font.mobile.size4};
        }
        
    }

    @media (max-width: ${responsive.size.tablet.landscape}) {
        font-size: ${responsive.font.mobile.size3};
    }
`;

class List extends React.Component {
    render() {

        let renderedItems = this.props.data.map((row,index) => {
            return(<StyledItem key={index}>
                <span className="primary">{ row.primary }</span>
                <span className="secondary">{ row.secondary }</span>
            </StyledItem>);
        }); 

        return (
        <StyledContainer>
            <StyledTitle>{ this.props.title }</StyledTitle>
            <StyledItemContainer>
                { renderedItems }
            </StyledItemContainer>
        </StyledContainer>
        )
    }
}

export default List;