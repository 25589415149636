import React from 'react';
import styled from 'styled-components';
import Title from '../../components/atoms/Title/Title';
import Text from '../../components/atoms/Text/Text';
import CoverImage2x from '../../assets/images/facebook-programming@2x.png';

const StyledCover = styled.img`
    display: block;
    width: 80%;
    margin: 0 auto;
`;

class Book extends React.Component {
    
    render() {

        return (
        <div className="page-row">
            <div className="page-column left">
                <Title primary="My Book:" secondary="Facebook Programming" />
                <Text text='I wrote the Facebook Programming book in 2011. The second edition was published within two months. The book content includes the usage of the Facebook API with PHP and JavaScript.' />
            </div>
            <div className="page-column right">
                <StyledCover src={CoverImage2x} />
            </div>
        </div>
        );
    }
}

export default Book;