import React from 'react';
import styled from 'styled-components';
import Title from '../../components/atoms/Title/Title';
import Text from '../../components/atoms/Text/Text';
import Button from '../../components/atoms/Button/Button';
import CoverImage from '../../assets/images/guinness-world-record.jpg';

const StyledImage = styled.img`
    display: block;
    width: 100%;
    margin: 0 auto;
`;

class Guinness extends React.Component {
    
    render() {

        return (
        <div className="page-row">
            <div className="page-column left">
                <Title primary="Guinness" secondary="World Chat Record" />
                <Text text="The longest marathon hosting an internet radio talk show by a team is 52 hours and was achieved by Nescafe in Istanbul, Turkey from 13 April - 15 April, 2012." />
                <Button text="Go to Guinness Page" href="https://www.guinnessworldrecords.com/world-records/210896-longest-marathon-hosting-an-internet-radio-talk-show-team" target="_blank" />
            </div>
            <div className="page-column right">
                <StyledImage src={CoverImage} />
            </div>
        </div>
        );
    }
}

export default Guinness;