import React from 'react';
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';
import ScrollToTop from 'react-router-scroll-top'
import Header from './components/molecules/Header';
import Experience from './pages/Experience/Experience';
import Projects from './pages/Projects/Projects';
import ProjectDetail from './pages/ProjectDetail/ProjectDetail';
import Book from './pages/Book/Book';
import Awards from './pages/Awards/Awards';
import Guinness from './pages/Guinness/Guinness';
import './App.css';

function App() {
  return (
    <div className="app-wrapper">
      <Router>
        <ScrollToTop>
          <Header />
          <Route exact path="/">
            { <Redirect to="/experience" /> }
          </Route>
          <Route path="/experience" component={Experience}></Route>
          <Route path="/projects" component={Projects}></Route>
          <Route path="/project/:slug" component={ProjectDetail}></Route>
          <Route path="/book" component={Book}></Route>
          <Route path="/awards" component={Awards}></Route>
          <Route path="/guinness-world-record" component={Guinness}></Route>
        </ScrollToTop>
      </Router>
    </div>
  );
}

var helloCoderText = "╭━━┳━━┳━━┳━━┳━━┳━━┳━━┳━━┳━━╮\n";
helloCoderText += "╰━━┻━━┻━━┻━━┻━━┻━━┻━━┻━━┻━━╯\n";
helloCoderText += "╭╮╱╭╮╭━━━╮╭╮╱╱╱╭╮╱╱╱╭━━━╮\n";
helloCoderText += "┃┃╱┃┃┃╭━━╯┃┃╱╱╱┃┃╱╱╱┃╭━╮┃\n";
helloCoderText += "┃╰━╯┃┃╰━━╮┃┃╱╱╱┃┃╱╱╱┃┃╱┃┃\n";
helloCoderText += "┃╭━╮┃┃╭━━╯┃┃╱╭╮┃┃╱╭╮┃┃╱┃┃\n";
helloCoderText += "┃┃╱┃┃┃╰━━╮┃╰━╯┃┃╰━╯┃┃╰━╯┃\n";
helloCoderText += "╰╯╱╰╯╰━━━╯╰━━━╯╰━━━╯╰━━━╯\n";
helloCoderText += "╭━━━╮╭━━━╮╭━━━╮╭━━━╮╭━━━╮╭╮\n";
helloCoderText += "┃╭━╮┃┃╭━╮┃╰╮╭╮┃┃╭━━╯┃╭━╮┃┃┃\n";
helloCoderText += "┃┃╱╰╯┃┃╱┃┃╱┃┃┃┃┃╰━━╮┃╰━╯┃┃┃\n";
helloCoderText += "┃┃╱╭╮┃┃╱┃┃╱┃┃┃┃┃╭━━╯┃╭╮╭╯╰╯\n";
helloCoderText += "┃╰━╯┃┃╰━╯┃╭╯╰╯┃┃╰━━╮┃┃┃╰╮╭╮\n";
helloCoderText += "╰━━━╯╰━━━╯╰━━━╯╰━━━╯╰╯╰━╯╰╯\n";
helloCoderText += "╭━━┳━━┳━━┳━━┳━━┳━━┳━━┳━━┳━━╮\n";
helloCoderText += "╰━━┻━━┻━━┻━━┻━━┻━━┻━━┻━━┻━━╯\n";
console.log("%c" + helloCoderText,"color:green");

var defaultTitle = document.title;
var blurTitle = "I'm waiting :)";

window.onfocus = function() {
    document.title = defaultTitle;
};
window.onblur = function() {
    document.title = blurTitle;
};

export default App;